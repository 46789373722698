import gql from 'graphql-tag'


const orderResponse = `
  id type name
  qty price totalPrice
  unitId unit {id name}
  stocks {
    id qty docId parentId orderId isPrinted
    inventoryId inventory {
      id code name supplierSku
      unitId unit {id name}
    }
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
  ledgers {
    id docId stockId orderId amount
    accountId account {id code name}
    createdAt createdBy createdUser {id name}
    approvedAt approvedBy approvedUser {id name}
  }
`

export const LIST_ORDER = (templateType) => gql`query LIST_ORDER ($docType: String!, $docId: Int!) {
  orders: listDoc${templateType}Order (docType: $docType, docId: $docId) {${orderResponse}}
}`


export const CREATE_ORDER_ITEM = (templateType) => gql`mutation CREATE_ORDER_ITEM ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderItemInput!) {
  createOrder: createDoc${templateType}OrderItem (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_EXCHANGE = (templateType) => gql`mutation CREATE_ORDER_EXCHANGE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderExchangeInput!) {
  createOrder: createDoc${templateType}OrderExchange (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_PRICE_DIFF = (templateType) => gql`mutation CREATE_ORDER_PRICE_DIFF ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderPriceDiffInput!) {
  createOrder: createDoc${templateType}OrderPriceDiff (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_EXPENSE = (templateType) => gql`mutation CREATE_ORDER_EXPENSE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderExpenseInput!) {
  createOrder: createDoc${templateType}OrderExpense (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_DISCOUNT_PRICE = (templateType) => gql`mutation CREATE_ORDER_DISCOUNT_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderDiscountPriceInput!) {
  createOrder: createDoc${templateType}OrderDiscountPrice (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_INVOICE_PRICE = (templateType) => gql`mutation CREATE_ORDER_INVOICE_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderInvoicePriceInput!) {
  createOrder: createDoc${templateType}OrderInvoicePrice (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_VAT_PRICE = (templateType) => gql`mutation CREATE_ORDER_VAT_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderVatPriceInput!) {
  createOrder: createDoc${templateType}OrderVatPrice (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`

export const CREATE_ORDER_NET_PRICE = (templateType) => gql`mutation CREATE_ORDER_NET_PRICE ($docType: String!, $docId: Int!, $input: Doc${templateType}OrderNetPriceInput!) {
  createOrder: createDoc${templateType}OrderNetPrice (docType: $docType, docId: $docId, input: $input) {${orderResponse}}
}`


export const DESTROY_ORDER = (templateType) => gql`mutation DESTROY_ORDER ($docType: String!, $orderId: Int!) {
  destroyOrder: destroyDoc${templateType}Order (docType: $docType, orderId: $orderId) {id}
}`


export const WATCH_ORDER_UPDATED = (templateType) => gql`subscription WATCH_ORDER_UPDATED ($docType: String!, $docId: Int!) {
  orderUpdated: watchDoc${templateType}OrderUpdated (docType: $docType, docId: $docId) {${orderResponse}}
}`

export const WATCH_ORDER_DESTROYED = (templateType) => gql`subscription WATCH_ORDER_DESTROYED ($docType: String!, $docId: Int!) {
  orderDestroyed: watchDoc${templateType}OrderDestroyed (docType: $docType, docId: $docId) {id parentId}
}`


export const LIST_ORDER_INVENTORY = (templateType) => gql`query LIST_ORDER_INVENTORY ($docType: String!, $q: FilterInput) {
  inventories: listDoc${templateType}OrderInventory (docType: $docType, q: $q) {
    id type name isDefault
    inventoryId inventory {id type code name}
  }
}`

export const LIST_ORDER_EXCHANGE = (templateType) => gql`query LIST_ORDER_EXCHANGE ($docType: String!, $q: FilterInput) {
  inventories: listDoc${templateType}OrderExchange (docType: $docType, q: $q) {
    id type name isDefault
    inventoryId inventory {id type code name}
  }
}`

export const LIST_ORDER_PRICE_DIFF = (templateType) => gql`query LIST_ORDER_PRICE_DIFF ($docType: String!, $q: FilterInput) {
  inventories: listDoc${templateType}OrderPriceDiff (docType: $docType, q: $q) {
    id type name isDefault
    inventoryId inventory {id type code name}
  }
}`

export const LIST_ORDER_OTHER_CONFIG = (templateType) => gql`query LIST_ORDER_OTHER_CONFIG ($docType: String!, $contactId: Int!) {
  others: listDoc${templateType}OrderOtherConfig (docType: $docType, contactId: $contactId) {
    id type name isDefault options
  }
}`
